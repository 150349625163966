import Head from 'next/head'
import {HomeComponent} from "../src/components";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import axios from "axios";
import {carsListMainEndpoint, getCurrentBannerEndpoint} from "../src/constants/api";
import {i18n} from "next-i18next";
import React from "react";

export async function getServerSideProps({ locale, resolvedUrl, res }) {

  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )

  const carsResponse = await axios.get(`${carsListMainEndpoint}`, { crossDomain: true })

  const bannerResponse = await axios.get(getCurrentBannerEndpoint, { crossDomain: true })
    .catch(console.log);

  const bannerData = bannerResponse.data;

  const stockCars = carsResponse.data.data.stock;

  const orderCars = carsResponse.data.data.order;

  const shippingCars = carsResponse.data.data.shipping;

  const cars = {
    stockCars: stockCars,
    orderCars: orderCars,
    shippingCars: shippingCars,
  }

  return {
    props: {
      banner: bannerData.data,
      resolvedUrl,
      ...cars,
      ...(await serverSideTranslations(locale || 'ua', ['common'])),
    },
  };
}

export default function IndexPage({...props}) {
  const {language: resolvedLanguage} = i18n || {};
  const { resolvedUrl } = props;
  return (
    <div>
      <Head
        title='Carfind'
      >
        <link
          href="https://fonts.googleapis.com/css2?family=Inter&display=swap"
          rel="preconnect"
        />
        <meta name="description" content="⚡ Купить авто из США и Канады в Украине ➤ Выгода до -4️⃣0️⃣% С популярных аукционов ✔️Copart ✔️Manheim  ✔️IAAI  ✔️IMPACT ⚡ Машины из США ☎️ 068 364 3801" lang='ru-UA'/>
        <meta name="description" content="⚡ Купити авто з США та Канади в Україні ➤ вигода до -4️⃣0️⃣% з популярних аукціонів ✔️Copart ✔️Manheim ✔️IAAI ✔️IMPACT ⚡ машини з США ☎️ 068 364 3801" lang='uk-UA'/>
        <title lang='ru-UA'>➤ Авто из США и Канады • Купить автомобили из Америки и Канады под ключ | CarFind</title>
        <title lang='uk-UA'>➤ Авто з США та Канади • купити автомобілі з Америки і Канади під ключ | CarFind</title>

        <link rel="canonical" href={resolvedLanguage === 'ua' ? `https://carfind.com.ua${resolvedUrl}` : `https://carfind.com.ua/ru${resolvedUrl}`} />
        <link rel="alternate" href={`https://carfind.com.ua/ru${resolvedUrl}`} lang="ru-UA"/>
        <link rel="alternate" href={`https://carfind.com.ua${resolvedUrl}`} hrefLang="uk-UA"/>
      </Head>
      <HomeComponent {...props}/>
    </div>
  )
}
